import React from 'react';
import { Link } from 'react-router-dom';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import onlineImage from "../../assets/online 4.png"
// import InPerson from "../../assets/in person 4.png"
import Testimonials from './Testimonials';
import BenefitsSection from './BenefitsSection';
import Services from './Services.js';
import Boost from './Boost.js';

import '../../styles/homepage style/Home.css';

function Home() {
  return (
    <div className="home">
      <div className="screen1">

      <Boost />
        {/* <div className="promo-section">
          
          <div className="promo-item1">
            <img src={InPerson} alt="Face-to-Face Tutoring" />
            <div className="promo-content">
              <h2>FACE-TO-FACE TUTORING</h2>
              <p className='f2f'>Join our face-to-face tutoring sessions in Sydney.</p>
              <Link to="/face-to-face-tutoring" className="promo-button">Learn More</Link>
            </div>
          </div>
          <div className="promo-item2">
            <img src={onlineImage} alt="Online Tutoring" />
            <div className="promo-content">
              <h2>ONLINE TUTORING</h2>
              <p className='online1'>Explore our comprehensive online tutoring programs.</p>
              <Link to="/online-tutoring" className="promo-button">Learn More</Link>
            </div>
          </div>
        </div> */}
        <Testimonials />
      </div>
      <div className="call-to-action">
        <h1>HIGHLY QUALIFIED & EXPERIENCED TUTORS IN SYDNEY</h1>
        <p className='body'>At Prime Tuition, we boast a team of accomplished tutors skilled in guiding students from Kindergarten to Year 12 across all primary and
          high-school subjects, including HSC, NAPLAN, and selective school test preperation.</p>
        <p className='body2'>We provide both private online and in-person tutoring tailored to meet individual academic needs.
          Our mission is to create a personalised and engaging tutoring experience that not only meets academic
          goals but also boosts student confidence.</p>
        <p className='body3'>Prime Tuition features a unique tutor matching program to ensure a perfect fit for each student, complete with a comprehensive
          resource folder to support their learning journey. Contact us today to learn more about how we can help enhance your child's educational
          experience!</p>
        <Link to="/book" className="book-button">Book a Tutor Today</Link>
      </div>
      <BenefitsSection />
      <Services />
    </div>
  );
}

export default Home;

