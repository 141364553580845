import React, { useState } from 'react';
import '../../styles/subjectsstyle/Physics.css';
import PhysicsImage from '../../assets/subjects.jpg';

const Physics = () => {
  const [activeTab, setActiveTab] = useState('year11');

  const handleTabClick = (year) => {
    setActiveTab('');
    setTimeout(() => {
      setActiveTab(year);
    }, 0); // Adjust the delay as needed for a smoother transition
  };


  return (

    <div className="physics-overview-container">

      <div className="physics-image-container">
        <img src={PhysicsImage} alt="Subjects" className="physics-image" />
        <div className="overlay-physics"></div>
        <div className="overlay-text-physics">
          <h1>Physics</h1>
       
        </div>
      </div>
      <div class="physics-book">
            <div class="physics-book-content">
              <h2>Book Physics Tutoring</h2>
              <p className='physics-p'>
              At Prime, our tutors make Physics accessible and interesting by creating a positive learning environment where students feel comfortable and motivated. 
              We offer comprehensive support for all high school Physics modules, from Preliminary to HSC courses, covering both foundational and advanced topics.     </p>
              <a href="/book">
    <button>Book Tutoring</button>
    </a>
            </div>
          </div>
          <div className='physics-statement'>
          <h1 className='physics-final'>“Unraveling the mysteries of the universe through Physics is fascinating. I enjoy showing students the beauty in complex concepts and the thrill 
            of solving challenging problems.”</h1>
            <p className='physics-name'>Anuj Savai | Physics Tutor
            </p>
          </div>
      <div className='physics-bg'>
        <h1 className="title">Course Overview</h1>
        <p className="subtitle">Select tab to pick year</p>
        <div className="physics-tabs">
          <button className={`physics-tab-button ${activeTab === 'year11' ? 'active' : ''}`} onClick={() => handleTabClick('year11')}>Year 11</button>
          <button className={`physics-tab-button ${activeTab === 'year12' ? 'active' : ''}`} onClick={() => handleTabClick('year12')}>Year 12</button>
        </div>
        <div id="year11" className={`physics-tab-content ${activeTab === 'year11' ? 'active' : ''}`}>
          <div className="physics-timeline">
            <div className="physics-timeline-item left">
              <div className="physics-timeline-content">
                <h3>Kinematics</h3>
                <p>Motion</p>
                <p>Kinematic Equations</p>
                <p>Vectors</p>
                <p>Forces</p>
              </div>
              <div className="physics-timeline-icon">
                <p>1</p>
              </div>
            </div>
            <div className="physics-timeline-item right">
              <div className="physics-timeline-content">
                <h3>Dynamics</h3>
                <p>Inclined Planes</p>
                <p>Multiple Body Systems</p>
                <p>Energy</p>
                <p>Momentum</p>
              </div>
              <div className="physics-timeline-icon">
                <p>2</p>
              </div>
            </div>
            <div className="physics-timeline-item left">
              <div className="physics-timeline-content">
                <h3>Waves and Thermodynamics</h3>
                <p>Waves</p>
                <p>Resonance</p>
                <p>Mirrors</p>
                <p>Heat</p>
              </div>
              <div className="physics-timeline-icon">
                <p>3</p>
              </div>
            </div>
            <div className="physics-timeline-item right">
              <div className="physics-timeline-content">
                <h3>Electricity and Magnetism</h3>
                <p>Electrostatics</p>
                <p>Electric Circuits</p>
                <p>Magnetic Forces</p>
                <p>Electromagnetism</p>
              </div>
              <div className="physics-timeline-icon">
                <p>4</p>
              </div>
            </div>

          </div>
        </div>
        <div id="year12" className={`physics-tab-content ${activeTab === 'year12' ? 'active' : ''}`}>
          <div className="physics-timeline">
            <div className="physics-timeline-item left">
              <div className="physics-timeline-content">
                <h3>Advanced Mechanics</h3>
                <p>Projectile Motion</p>
                <p>Circular Motion</p>
                <p>Banked Surfaces</p>
                <p>Gravity</p>
              </div>
              <div className="physics-timeline-icon">
                <p>1</p>
              </div>
            </div>
            <div className="physics-timeline-item right">
              <div className="physics-timeline-content">
                <h3>Electromagnetism</h3>
                <p>Electric and Magnet Fields</p>
                <p>Motors</p>
                <p>Electromagnetic Induction</p>
                <p>Transformers</p>
              </div>
              <div className="physics-timeline-icon">
                <p>2</p>
              </div>
            </div>
            <div className="physics-timeline-item left">
              <div className="physics-timeline-content">
                <h3>The Nature of Light</h3>
                <p>The Nature of Light</p>
                <p>Electromagnetic Spectrum</p>
                <p>Wave Model of Light</p>
                <p>Quantum Model of Light</p>
                <p>Light and Special Relativity</p>
              </div>
              <div className="physics-timeline-icon">
                <p>3</p>
              </div>

            </div>
            <div className="physics-timeline-item right">
              <div className="physics-timeline-content">
                <h3>From the Universe to the Atom</h3>
                <p>Origins of the Elements</p>
                <p>Atomic Structure</p>
                <p>Nuclear Properties</p>
                <p>Standard Model of Matter</p>
              </div>
              <div className="physics-timeline-icon">
                <p>4</p>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default Physics;