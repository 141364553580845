import React from 'react';
import { Link } from 'react-router-dom';
import '../../styles/subjectsstyle/Subjects.css'; // Adjust the CSS file as needed
import SubjectImage from '../../assets/subjects.jpg';
import Form from '../book/Form';

function Subjects() {
    return (
        <div className="subjects-section">
            <div className="subject-image-container">
                <img src={SubjectImage} alt="Subjects" className="subject-image" />
                <div className="overlay-subject"></div>
                <div className="overlay-text-subject">
                    <h1>Subjects</h1>
                </div>
            </div>
            <div className="subject-text1">
                <h2>Discover Tutoring for HSC, OC, and Selective Exams</h2>
                <p>Browse through the icons below to learn more about some of the HSC subjects we offer. If the subject you're looking for isn't visible,
                    don't worry! We provide tutoring in a diverse range of courses (including OC and Selective School Preparation). Just fill out the form at the bottom
                    of this page, select 'Other' from the subject options, and we'll promptly reach out to help you further.
                </p>
            </div>
            <div className="subjects-icons">
                <div className="middle-line"></div>
                <Link to="/subjects/chemistry" className="subject-icon">
                    <img src={require('../../assets/subjecticons/chemistry-icon.png')} alt="Chemistry" />
                    <p>Chemistry</p>
                </Link>
                <Link to="/subjects/physics" className="subject-icon">
                    <img src={require('../../assets/subjecticons/physics-icon.png')} alt="Physics" />
                    <p>Physics</p>
                </Link>
                <Link to="/subjects/biology" className="subject-icon">
                    <img src={require('../../assets/subjecticons/biology-icon.png')} alt="Biology" />
                    <p>Biology</p>
                </Link>
                <Link to="/subjects/geography" className="subject-icon">
                    <img src={require('../../assets/subjecticons/geography-icon.png')} alt="Geography" />
                    <p>Geography</p>
                </Link>
                <Link to="/subjects/mathematics" className="subject-icon">
                    <img src={require('../../assets/subjecticons/mathematics-icon.png')} alt="Mathematics" />
                    <p>Mathematics</p>
                </Link>
                <Link to="/subjects/english" className="subject-icon">
                    <img src={require('../../assets/subjecticons/english-icon.png')} alt="English" />
                    <p>English</p>
                </Link>
                <Link to="/subjects/economics" className="subject-icon">
                    <img src={require('../../assets/subjecticons/economics-icon.png')} alt="Economics" />
                    <p>Economics</p>
                </Link>
                <Link to="/subjects/history" className="subject-icon">
                    <img src={require('../../assets/subjecticons/history-icon.png')} alt="History" />
                    <p>History</p>
                </Link>
            </div>
            <Form />
        </div>
    );
}

export default Subjects;
