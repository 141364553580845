import React, { useState } from 'react';
import '../../styles/subjectsstyle/Chemistry.css';
import chemImage from '../../assets/subjects.jpg';

const Chemistry = () => {
  const [activeTab, setActiveTab] = useState('year11');

  const handleTabClick = (year) => {
    setActiveTab('');
    setTimeout(() => {
      setActiveTab(year);
    }, 0); // Adjust the delay as needed for a smoother transition
  };


  return (
  
    <div className="chem-overview-container">
      
      <div className="chem-image-container">
        <img src={chemImage} alt="Subjects" className="chem-image" />
        <div className="overlay-chem"></div>
        <div className="overlay-text-chem">
          <h1>Chemistry</h1>
        </div>
      </div>
      <div class="chemistry-book">
  <div class="chemistry-book-content">
    <h2>Book Chemistry Tutoring</h2>
    <p className='book-p'>
    At Prime, our dedicated tutors make Chemistry approachable and engaging by pairing students with Sydney based tutors who create a supportive learning environment. 
    We cover all Preliminary and HSC Chemistry modules, from atomic structure to organic chemistry, providing personalised resource folders with past exam papers, 
    model answers, and study guides. </p>
    
    <a href="/book">
    <button>Book Tutoring</button>
    </a>
  </div>
</div>
      <div className='chem-statement'>
          <h1 className='chem-final'>“Chemistry is like solving a molecular puzzle. I love helping students grasp the core principles and witnessing their 
            excitement when it all comes together.”</h1>
            <p className='chem-name'>Melissa Thein | Chemistry Tutor
            </p>
          </div>
 
      <div className='chem-bg'>
      <h1 className="chem-title">Course Overview</h1>
      <p className="chem-subtitle">Select tab to pick year</p>
      <div className="chem-tabs">
        <button className={`chem-tab-button ${activeTab === 'year11' ? 'active' : ''}`} onClick={() => handleTabClick('year11')}>Year 11</button>
        <button className={`chem-tab-button ${activeTab === 'year12' ? 'active' : ''}`} onClick={() => handleTabClick('year12')}>Year 12</button>
      </div>
      <div id="year11" className={`chem-tab-content ${activeTab === 'year11' ? 'active' : ''}`}>
        <div className="chem-timeline">
          <div className="chem-timeline-item left">
            <div className="chem-timeline-content">
              <h3>Properties and Structures of Matter</h3>
              <p>Properties of Matter</p>
              <p>Atomic Structure</p>
              <p>Periodic Trends</p>
              <p>Bonding</p>
            </div>
            <div className="chem-timeline-icon">
              <p>1</p>
            </div>
          </div>
          <div className="chem-timeline-item right">
            <div className="chem-timeline-content">
              <h3>Introduction to Quantitative Chemistry</h3>
              <p>Stoichiometry</p>
              <p>Mole Concept</p>
              <p>Concentration</p>
              <p>Gas Laws</p>
            </div>
            <div className="chem-timeline-icon">
              <p>2</p>
            </div>
          </div>
          <div className="chem-timeline-item left">
            <div className="chem-timeline-content">
              <h3>Reactive Chemistry</h3>
              <p>Type of Chemical Reactions</p>
              <p>Reactions of Metals</p>
              <p>Redox Chemistry</p>
              <p>Rates of Reaction</p>
            </div>
            <div className="chem-timeline-icon">
              <p>3</p>
            </div>
          </div>
          <div className="chem-timeline-item right">
            <div className="chem-timeline-content">
              <h3>Drivers of Reactions</h3>
              <p>Energy Changes</p>
              <p>Enthalpy and Hess’s Law</p>
              <p>Entrop</p>
              <p>Gibbs Free Energy</p>
            </div>
            <div className="chem-timeline-icon">
              <p>4</p>
            </div>
          </div>

        </div>
      </div>
      <div id="year12" className={`chem-tab-content ${activeTab === 'year12' ? 'active' : ''}`}>
        <div className="chem-timeline">
          <div className="chem-timeline-item left">
            <div className="chem-timeline-content">
            <h3>Equilibrium and Acid Reactions</h3>
              <p>Equilibrium Reactions</p>
              <p>Le Chatelier’s Principle</p>
              <p>Equilibrium Constant</p>
              <p>Solubility</p>
            </div>
            <div className="chem-timeline-icon">
              <p>1</p>
            </div>
          </div>
          <div className="chem-timeline-item right">
            <div className="chem-timeline-content">
            <h3>Acid / Base Reactions</h3>
              <p>Properties of Acids and Bases</p>
              <p>Bronsted-Lowry Theory</p>
              <p>Titration</p>
              <p>Buffers</p>
            </div>
            <div className="chem-timeline-icon">
              <p>2</p>
            </div>
          </div>
          <div className="chem-timeline-item left">
            <div className="chem-timeline-content">
              <h3>The Nature of Light</h3>
              <p>The Nature of Light</p>
              <p>Electromagnetic Spectrum</p>
              <p>Wave Model of Light</p>
              <p>Quantum Model of Light</p>
              <p>Light and Special Relativity</p>
            </div>
            <div className="chem-timeline-icon">
              <p>3</p>
            </div>

          </div>
          <div className="chem-timeline-item right">
            <div className="chem-timeline-content">
              <h3>From the Universe to the Atom</h3>
              <p>Origins of the Elements</p>
              <p>Atomic Structure</p>
              <p>Nuclear Properties</p>
              <p>Standard Model of Matter</p>
            </div>
            <div className="chem-timeline-icon">
              <p>4</p>
            </div>
          </div>
          </div>
        </div>
      </div>
     
    </div>
  );
};

export default Chemistry;