import React, { useState } from 'react';
import '../../styles/subjectsstyle/Economics.css';
import economicsImage from '../../assets/subjects.jpg';

const Economics = () => {
  const [activeTab, setActiveTab] = useState('year11');

  const handleTabClick = (year) => {
    setActiveTab('');
    setTimeout(() => {
      setActiveTab(year);
    }, 0); // Adjust the delay as needed for a smoother transition
  };


  return (
  
    <div className="economics-overview-container">
      
      <div className="economics-image-container">
        <img src={economicsImage} alt="Subjects" className="economics-image" />
        <div className="overlay-economics"></div>
        <div className="overlay-text-economics">
          <h1>Economics</h1>
        </div>
      </div>
      <div class="economics-book">
  <div class="economics-book-content">
    <h2>Book Economics Tutoring</h2>
    <p className='economics-p'>
    At Prime, our tutors make Economics accessible and engaging by creating a supportive learning environment. 
    We provide comprehensive support for all high school Economics modules, from Preliminary to HSC, ensuring students excel in both 
    foundational principles and advanced theories </p>
    
    <a href="/book">
    <button>Book Tutoring</button>
    </a>
  </div>
</div>
      <div className='economics-statement'>
          <h1 className='economics-final'>"Grasping the complexities of Economics is both intriguing and gratifying. 
            I aim to ignite students' interest in economic concepts, making the subject engaging and enjoyable to learn."</h1>
            <p className='economics-name'>Kai Fung Ong | Economics Tutor</p>
          </div>
      
      <div className='economics-bg'>
      <h1 className="economics-title">Course Overview</h1>
      <p className="economics-subtitle">Select tab to pick year</p>
      <div className="economics-tabs">
        <button className={`economics-tab-button ${activeTab === 'year11' ? 'active' : ''}`} onClick={() => handleTabClick('year11')}>Year 11</button>
        <button className={`economics-tab-button ${activeTab === 'year12' ? 'active' : ''}`} onClick={() => handleTabClick('year12')}>Year 12</button>
      </div>
      <div id="year11" className={`economics-tab-content ${activeTab === 'year11' ? 'active' : ''}`}>
        <div className="economics-timeline">
          <div className="economics-timeline-item left">
            <div className="economics-timeline-content">
              <h3>Introduction to Economics </h3>
              <p>Opportunity Cost</p>
              <p>Circular Flow of Income</p>
              <p>Economies of Scale</p>
              
            </div>
            <div className="economics-timeline-icon">
              <p>1</p>
            </div>
          </div>
          <div className="economics-timeline-item right">
            <div className="economics-timeline-content">
              <h3>Consumers and Business</h3>
              <p>Goals of Firms</p>
              <p>Consumer Sovereignty</p>
              <p>Technological Change</p>
             
            </div>
            <div className="economics-timeline-icon">
              <p>2</p>
            </div>
          </div>
          <div className="economics-timeline-item left">
            <div className="economics-timeline-content">
              <h3>Introduction to Markets </h3>
              <p>Demand, Supply and Market Equilibrium</p>
              <p>Price Elasticity of Demand and Supply</p>
              <p>Market Structures</p>
            
            </div>
            <div className="economics-timeline-icon">
              <p>3</p>
            </div>
          </div>
          <div className="economics-timeline-item right">
            <div className="economics-timeline-content">
              <h3>Labour Markets</h3>
              <p>Demand for Labour</p>
              <p>Supply for Labour</p>
              <p>Trends in Labour Market</p>
              <p>Labour Market Institutions</p>
            
            </div>
            <div className="economics-timeline-icon">
              <p>4</p>
            </div>
          </div>
          <div className="economics-timeline-item left">
            <div className="economics-timeline-content">
              <h3>Financial Markets</h3>
              <p>Stock Market</p>
             
              <p>Regulation of Financial Markets</p>
              <p>Monetary Policy</p>
            
            </div>
            <div className="economics-timeline-icon">
              <p>5</p>
            </div>
          </div>
          <div className="economics-timeline-item right">
            <div className="economics-timeline-content">
              <h3>Government and the Economy</h3>
              <p>Market Failure</p>
             
              <p>Functions of the Government</p>
              <p>Fiscal Policy</p>
            
            </div>
            <div className="economics-timeline-icon">
              <p>6</p>
            </div>
          </div>
        </div>
      </div>
      <div id="year12" className={`economics-tab-content ${activeTab === 'year12' ? 'active' : ''}`}>
        <div className="economics-timeline-12">
          <div className="economics-timeline-item left">
            <div className="economics-timeline-content">
            <h3>The Global Economy</h3>
              <p>International Economic Integration</p>
              <p>Globalisation and Economic Development</p>
              <p>DNA and Polypeptide Synthesis</p>
              <p>Introduction to Protection</p>
              
            </div>
            <div className="economics-timeline-icon">
              <p>1</p>
            </div>
          </div>
          <div className="economics-timeline-item right">
            <div className="economics-timeline-content">
            <h3>Australia’s Place in the Global Economy</h3>
              <p>Balance of Payments</p>
              <p>Exchange Rates</p>
              <p>Free Trade and Protection</p>
             
            </div>
            <div className="economics-timeline-icon">
              <p>2</p>
            </div>
          </div>
          <div className="economics-timeline-item left">
            <div className="economics-timeline-content">
              <h3>Economic Issues</h3>
              <p>Unemployment</p>
              <p>Distribution of Wealth</p>
              <p>Inflation</p>
              <p>Quantum Model of Light</p>
              <p>External Stability</p>
            </div>
            <div className="economics-timeline-icon">
              <p>3</p>
            </div>

          </div>
          <div className="economics-timeline-item right">
            <div className="economics-timeline-content">
              <h3>Economic Policies and Management</h3>
              <p>Conflicts in Policies</p>
              <p>Fiscal Policy and Monetary Policy</p>
              <p>Microeconomic Reform</p>
              <p>Labour Market Policies</p>
              <p>Limitations of Economic Policies</p>
            </div>
            <div className="economics-timeline-icon">
              <p>4</p>
            </div>
          </div>
          </div>
        </div>
      </div>
     
    </div>
  );
};

export default Economics;