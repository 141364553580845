import React from 'react';
import '../../styles/bookstyle/Book.css';
import bookImage from '../../assets/book.jpg';
import { ReactComponent as TelephoneIcon } from '../../assets/icons/TelephoneIcon.svg'; // Import the telephone icon
import { ReactComponent as EmailIcon } from '../../assets/icons/EmailIcon.svg'; // Import the email icon
import Steps from './Steps';
import Form from './Form';

const Booking = () => {
  const containerStyle = {
    bookImage: `url(${bookImage})`,
  };

  return (
    <div className="booking-container">
      <div className="image-section-book" style={containerStyle}>
      <img src={bookImage} alt="Book" className="book-image" />
        <div className="overlaybook">
          <h2 className="overlay-text-book">Book Tutoring</h2>
        </div>
      </div>
      <div className="content-section-book">
        <h1>Book your first lesson with a Prime Tutor</h1>
        <p>
          If you are searching for private tutoring in Sydney, then get in touch with us today to learn more about our face-to-face and online tutoring sessions. Feel free to fill out the form below, or alternatively you can call or send us an email, and a member of our team will be in touch shortly to answer any questions that you may have and confirm your booking.
        </p>
        <div className="contact-deets">
          <p>
            <span className="icon"><TelephoneIcon /></span> 0414 201 678
          </p>
          <p>
            <span className="icon"><EmailIcon /></span> info@primetuition.org
          </p>
        </div>
      </div>
      <Steps />
      <Form />
    </div>
  );
};

export default Booking;
