import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom'; 
import '../styles/Header.css';
import '../styles/HeaderMobile.css';
import logo from '../assets/website-logo2.png';


function Header() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isAboutUsOpen, setAboutUsOpen] = useState(false);
  const [isSubjectsOpen, setSubjectsOpen] = useState(false);
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isDesktopView, setIsDesktopView] = useState(window.innerWidth > 768);
  const headerRef = useRef(null);

  const closeDropdowns = () => {
    setAboutUsOpen(false);
    setSubjectsOpen(false);
    setMobileMenuOpen(false);
  };

  useEffect(() => {
    closeDropdowns();
  }, [location]);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktopView(window.innerWidth > 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (headerRef.current && !headerRef.current.contains(event.target)) {
        closeDropdowns();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleMouseEnter = (setDropdownOpen) => {
    if (isDesktopView) {
      setDropdownOpen(true);
    }
  };

  const handleMouseLeave = (setDropdownOpen) => {
    if (isDesktopView) {
      setDropdownOpen(false);
    }
  };

  const handleNavigate = (path) => {
    closeDropdowns();
    window.scrollTo(0, 0);
    navigate(path);
  };

  return (
    <header className="header" ref={headerRef}>
      <div className="logo">
        <Link to="/" onClick={() => handleNavigate('/')}>

        <img src={logo} className="logo" alt="Prime Tuition" />
        </Link>
      </div>
      <button 
        className={`hamburger ham8 ${isMobileMenuOpen ? 'open' : ''}`} 
        onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}>
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
      </button>
      <nav className={`nav-bar ${isMobileMenuOpen ? 'open' : ''}`}>
        <ul className="nav-list">
          <li><button className="header-button" onClick={() => handleNavigate('/')}>Home</button></li>

          <li
            className={`dropdown ${isAboutUsOpen ? 'open' : ''}`}
            onMouseEnter={() => handleMouseEnter(setAboutUsOpen)}
            onMouseLeave={() => handleMouseLeave(setAboutUsOpen)}
          >
            <button className="header-button" onClick={() => handleNavigate('/about-us')}>About Us</button>
            {isDesktopView && (
              <div className={`dropdown-content ${isAboutUsOpen ? 'show' : ''}`}>
                <Link to="/about-us/company-profile" onClick={() => handleNavigate('/about-us/company-profile')}>Company Profile</Link>
                {/* <Link to="/about-us/team" onClick={() => handleNavigate('/about-us/team')}>Our Team</Link> */}
              </div>
            )}
          </li>

          <li
            className={`dropdown ${isSubjectsOpen ? 'open' : ''}`}
            onMouseEnter={() => handleMouseEnter(setSubjectsOpen)}
            onMouseLeave={() => handleMouseLeave(setSubjectsOpen)}
          >
            <button className="header-button" onClick={() => handleNavigate('/subjects')}>Subjects</button>
            {isDesktopView && (
              <div className={`dropdown-content ${isSubjectsOpen ? 'show' : ''}`}>
                <Link to="/subjects/mathematics" onClick={() => handleNavigate('/subjects/mathematics')}>Mathematics</Link>
                <Link to="/subjects/english" onClick={() => handleNavigate('/subjects/english')}>English</Link>
                <Link to="/subjects/physics" onClick={() => handleNavigate('/subjects/physics')}>Physics</Link>
                <Link to="/subjects/chemistry" onClick={() => handleNavigate('/subjects/chemistry')}>Chemistry</Link>
                <Link to="/subjects/biology" onClick={() => handleNavigate('/subjects/biology')}>Biology</Link>
                <Link to="/subjects/economics" onClick={() => handleNavigate('/subjects/economics')}>Economics</Link>
                <Link to="/subjects/geography" onClick={() => handleNavigate('/subjects/geography')}>Geography</Link>
                <Link to="/subjects/history" onClick={() => handleNavigate('/subjects/history')}>History</Link>
              </div>
            )}
          </li>

          <li><button className="header-button" onClick={() => handleNavigate('/pricing')}>Pricing</button></li>
          <li><button className="header-button" onClick={() => handleNavigate('/FAQs')}>FAQs</button></li>
         
          <li><button className="header-button" onClick={() => handleNavigate('/contact-us')}>Contact Us</button></li>
          <li><button className="book" onClick={() => handleNavigate('/book')}>Book Now</button></li>
        </ul>

        <div className="header-social">
        <a href="https://www.facebook.com/profile.php?id=61565950077366" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-facebook"></i>
        </a>
        <a href="https://www.instagram.com/prime_tuition/?utm_source=ig_web_button_share_sheet" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-instagram"></i>
        </a>
        <a href="https://www.tiktok.com/@prime_tuition?is_from_webapp=1&sender_device=pc" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-tiktok"></i>
        </a>
      </div>
      </nav>
   
    </header>
  );
}

export default Header;
