import React from 'react';
import '../../styles/homepage style/Online.css';
import onlineImage from "../../assets/laptop.jpg";

function OnlinePrime() {
 
  return (
    <div className="online-prime">
      <div className="image-container-online">
        <img src={onlineImage} alt="Online Prime Tuition" className="online-image" />
        <div className="overlay2"></div>
        <div className="overlay-text-online">
          <h1>Online Tutoring</h1>
        </div>
        
      </div>
      <div className="online-content">
        <h2>Anywhere and Anytime </h2>
        <p>
        Managing a busy family schedule can be challenging, especially with the demands of traditional tutoring. 
        Our online interactive learning platform offers a stress-free solution by bringing high-quality, personalised tutoring right to your home.
        Through live, face-to-face sessions, your child can connect with experienced tutors from across Australia, ensuring they receive the best educational 
        support regardless of location.
        </p>
        <p>
        Our education team regularly reviews each session to keep you informed about your child's progress and ensure the highest quality learning experience. 
        This ongoing assessment allows us to continuously refine our approach, giving you peace of mind and allowing you to focus on other important aspects of your 
        child's education.
        </p>
        <p>
        We use platforms like Zoom and Google Meet to facilitate dynamic, interactive learning experiences where students and tutors can collaborate effortlessly. 
        With our online sessions, you can enjoy the convenience and effectiveness of professional educational support without the hassle of travel.
        </p>
      </div>
      
    </div>
  );
}

export default OnlinePrime;