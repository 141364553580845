import React, { useState } from 'react';
import '../../styles/subjectsstyle/Geography.css';
import geographyImage from '../../assets/subjects.jpg';

const Geography = () => {
  const [activeTab, setActiveTab] = useState('year11');

  const handleTabClick = (year) => {
    setActiveTab('');
    setTimeout(() => {
      setActiveTab(year);
    }, 0); // Adjust the delay as needed for a smoother transition
  };


  return (
  
    <div className="geography-overview-container">
      
      <div className="geography-image-container">
        <img src={geographyImage} alt="Subjects" className="geography-image" />
        <div className="overlay-geography"></div>
        <div className="overlay-text-geography">
          <h1>Geography</h1>
        </div>
      </div>
      <div class="geography-book">
  <div class="geography-book-content">
    <h2>Book Geography Tutoring</h2>
    <p className='geography-p'>
    At Prime, our dedicated tutors make Geography accessible and engaging by connecting students with Sydney-based experts who 
    foster a supportive learning environment. 
     </p>
     <p className='geography-p'>We cover all Preliminary and HSC Geography modules, from Earth's Natural Systems to Rural and Urban Places, 
    providing personalised resource folders with past exam papers, model answers, and comprehensive study guides. </p>
    <p className='geography-p'>Our tailored approach ensures each student can excel in their studies and develop a deep understanding of geographic concepts and skills.</p>
    <a href="/book">
    <button>Book Tutoring</button>
    </a>
  </div>
</div>
      <div className='geography-statement'>
          <h1 className='geography-final'>"Delving into the diverse landscapes and human interactions within our world is both captivating 
            and enlightening. My goal is to inspire students to see the relevance and excitement in Geography, transforming learning into an
            engaging and enriching experience."</h1>
            <p className='geography-name'>Catherine Lee | Geography Tutor</p>
          </div>
      
      <div className='geography-bg'>
      <h1 className="geography-title">Course Overview</h1>
      <p className="geography-subtitle">Select tab to pick year</p>
      <div className="geography-tabs">
        <button className={`geography-tab-button ${activeTab === 'year11' ? 'active' : ''}`} onClick={() => handleTabClick('year11')}>Year 11</button>
        <button className={`geography-tab-button ${activeTab === 'year12' ? 'active' : ''}`} onClick={() => handleTabClick('year12')}>Year 12</button>
      </div>
      <div id="year11" className={`geography-tab-content ${activeTab === 'year11' ? 'active' : ''}`}>
        <div className="geography-timeline">
          <div className="geography-timeline-item left">
            <div className="geography-timeline-content">
              <h3>Earth's Natural Systems</h3>
              <p>Diverse Landscapes and Features</p>
              <p>Cycles, Circulation, Interconnections</p>
              
            </div>
            <div className="geography-timeline-icon">
              <p>1</p>
            </div>
          </div>
          <div className="geography-timeline-item right">
            <div className="geography-timeline-content">
              <h3>People, Patterns and Processes</h3>
              <p>Evidence of Human Diversity</p>
              <p>Spatial Patterns and Human Footprint</p>
             
            </div>
            <div className="geography-timeline-icon">
              <p>2</p>
            </div>
          </div>
          <div className="geography-timeline-item left">
            <div className="geography-timeline-content">
              <h3>Human–Environment Interactions</h3>
              <p>Global Nature of Land Cover Change</p>
              <p>Long-Term Development of Natural Systems</p>
              <p>Time Frame of Human Activity</p>
             
            </div>
            <div className="geography-timeline-icon">
              <p>3</p>
            </div>
          </div>
          <div className="geography-timeline-item right">
            <div className="geography-timeline-content">
              <h3>Geographical Investigation</h3>
              <p>Plan and Conduct One Investigation</p>
              <p>Develop Understanding of Nature</p>
              
            
            </div>
            <div className="geography-timeline-icon">
              <p>4</p>
            </div>
          </div>

        </div>
      </div>
      <div id="year12" className={`geography-tab-content ${activeTab === 'year12' ? 'active' : ''}`}>
        <div className="geography-timeline-12">
          <div className="geography-timeline-item left">
            <div className="geography-timeline-content">
            <h3>Global Sustainability</h3>
              <p>Sustainability in Contemporary World</p>
              <p>Global Economic Activity</p>
         
            </div>
            <div className="geography-timeline-icon">
              <p>1</p>
            </div>
          </div>
          <div className="geography-timeline-item right">
            <div className="geography-timeline-content">
            <h3>Rural and Urban Places</h3>
              <p>Diversity of Settlements</p>
              <p>Rural, Urban, Cities</p>
            
             
            </div>
            <div className="geography-timeline-icon">
              <p>2</p>
            </div>
          </div>
          <div className="geography-timeline-item left">
            <div className="geography-timeline-content">
              <h3>Ecosystems and Global Biodiversity</h3>
              <p>Functioning of Ecosystems</p>
              <p>Roles of Natural and Human Stresses</p>
              <p>Trends in Global Diversity</p>
              <p>Study of Two Different Ecosystems</p>
              
            </div>
            <div className="geography-timeline-icon">
              <p>3</p>
            </div>

          </div>
          
          </div>
        </div>
      </div>
     
    </div>
  );
};

export default Geography;