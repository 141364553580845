import React, { useState } from 'react';
import '../../styles/subjectsstyle/Mathematics.css';
import mathematicsImage from '../../assets/subjects.jpg';

const Mathematics = () => {
  const [activeMainTab, setActiveMainTab] = useState('math_standard');
  const [activeSubTab, setActiveSubTab] = useState('year11');

  const handleMainTabClick = (mainTab) => {
    setActiveMainTab(mainTab);
    setActiveSubTab(mainTab === 'extension2' ? 'year12' : 'year11'); // Reset to year12 for extension2
  };
  

  const handleSubTabClick = (subTab) => {
    setActiveSubTab(subTab);
  };

  const renderContent = () => {
    if (activeMainTab === 'math_standard') {
      if (activeSubTab === 'year11') {
        return (
          <div className="mathematics-timeline math_standard-year11">
            <div className="mathematics-timeline-item left">
              <div className="mathematics-timeline-content">
                <h3>Algebra</h3>
                <p>Linear Relationships</p>
                <p>Formulae and Equations</p>
                <p>Decimals and Percentages</p>
              </div>
              <div className="mathematics-timeline-icon">
                <p>1</p>
              </div>
            </div>
            <div className="mathematics-timeline-item right">
              <div className="mathematics-timeline-content">
                <h3>Measurement</h3>
                <p>Applications of Measurement</p>
                <p>Working with Time</p>
              </div>
              <div className="mathematics-timeline-icon">
                <p>2</p>
              </div>
            </div>
            <div className="mathematics-timeline-item left">
              <div className="mathematics-timeline-content">
                <h3>Financial Mathematics</h3>
                <p>Money Matters</p>
              </div>
              <div className="mathematics-timeline-icon">
                <p>3</p>
              </div>
            </div>
            <div className="mathematics-timeline-item right">
              <div className="mathematics-timeline-content">
                <h3>Statistical Analysis</h3>
                <p>Data Analysis</p>
                <p>Relative Frequency and Probability</p>
              </div>
              <div className="mathematics-timeline-icon">
                <p>4</p>
              </div>
            </div>
          </div>
        );
      } else if (activeSubTab === 'year12') {
        return (
          <div className="mathematics-timeline math_standard-year12">
            <div className="mathematics-timeline-item left">
              <div className="mathematics-timeline-content">
                <h3>Algebra</h3>
                <p>Quadratic Equations</p>
                <p>Relations</p>
                <p>Types of Relationships</p>
              </div>
              <div className="mathematics-timeline-icon">
                <p>1</p>
              </div>
            </div>
            <div className="mathematics-timeline-item right">
              <div className="mathematics-timeline-content">
                <h3>Measurement</h3>
                <p>Rates and Ratios</p>
                <p>Non-right Angled Trigonometry</p>
                <p>Scale Drawings</p>
              </div>
              <div className="mathematics-timeline-icon">
                <p>2</p>
              </div>
            </div>
            <div className="mathematics-timeline-item left">
              <div className="mathematics-timeline-content">
                <h3>Financial Mathematics</h3>
                <p>Investments and Loans</p>
                <p>Annuities</p>
              </div>
              <div className="mathematics-timeline-icon">
                <p>3</p>
              </div>
            </div>
            <div className="mathematics-timeline-item right">
              <div className="mathematics-timeline-content">
                <h3>Statistics and Networks</h3>
                <p>Bivariate Data Analysis</p>
                <p>The Normal Distribution</p>
                <p>Network Concepts</p>
                <p>Critical Path Analysis</p>
              </div>
              <div className="mathematics-timeline-icon">
                <p>4</p>
              </div>
            </div>
          </div>
        );
      }
    } else if (activeMainTab === 'math_advanced') {
      if (activeSubTab === 'year11') {
        return (
          <div className="mathematics-timeline math_advanced-year11">
            <div className="mathematics-timeline-item left">
              <div className="mathematics-timeline-content">
                <h3>Functions</h3>
                <p>Functions, Relations and Graphs</p>
                <p>Graphing Quadratics</p>
                <p>Asymptotes</p>
              </div>
              <div className="mathematics-timeline-icon">
                <p>1</p>
              </div>
            </div>
            <div className="mathematics-timeline-item right">
              <div className="mathematics-timeline-content">
                <h3>Trigonometry</h3>
                <p>Measure of Angles</p>
                <p>Functions</p>
                <p>Identities</p>
              </div>
              <div className="mathematics-timeline-icon">
                <p>2</p>
              </div>
            </div>
            <div className="mathematics-timeline-item left">
              <div className="mathematics-timeline-content">
                <h3>Exponentials and Logarithms</h3>
                <p>Laws for logarithms</p>
                <p>Indices and Equations</p>
              </div>
              <div className="mathematics-timeline-icon">
                <p>3</p>
              </div>
            </div>
            <div className="mathematics-timeline-item right">
              <div className="mathematics-timeline-content">
                <h3>Calculus and Statistics</h3>
                <p>Introduction to Differentiation</p>
                <p>Probability Distributions</p>
              </div>
              <div className="mathematics-timeline-icon">
                <p>4</p>
              </div>
            </div>
          </div>
        );
      } else if (activeSubTab === 'year12') {
        return (
          <div className="mathematics-timeline math_advanced-year12">
            <div className="mathematics-timeline-item left">
              <div className="mathematics-timeline-content">
                <h3>Functions</h3>
                <p>Graphing Techniques</p>
                <p>Trigonometric Functions and Graphs</p>
              </div>
              <div className="mathematics-timeline-icon">
                <p>1</p>
              </div>
            </div>
            <div className="mathematics-timeline-item right">
              <div className="mathematics-timeline-content">
                <h3>Calculus</h3>
                <p>Differential Calculus</p>
                <p>The Second Derivative</p>
                <p>Integral Calculus</p>
              </div>
              <div className="mathematics-timeline-icon">
                <p>2</p>
              </div>
            </div>
            <div className="mathematics-timeline-item left">
              <div className="mathematics-timeline-content">
                <h3>Financial Mathematics</h3>
                <p>Modelling Financial Situations</p>
              </div>
              <div className="mathematics-timeline-icon">
                <p>3</p>
              </div>
            </div>
            <div className="mathematics-timeline-item right">
              <div className="mathematics-timeline-content">
                <h3>Statistics</h3>
                <p>Descriptive Statistics</p>
                <p>Statistical Analysis</p>
                <p>Random Variables</p>
              </div>
              <div className="mathematics-timeline-icon">
                <p>4</p>
              </div>
            </div>
          </div>
        );
      }
    } else if (activeMainTab === 'math_extension1') {
      if (activeSubTab === 'year11') {
        return (
          <div className="mathematics-timeline math_extension1-year11">
            <div className="mathematics-timeline-item left">
              <div className="mathematics-timeline-content">
                <h3>Functions</h3>
                <p>Further Functions</p>
                <p>Polynomials</p>
              </div>
              <div className="mathematics-timeline-icon">
                <p>1</p>
              </div>
            </div>
            <div className="mathematics-timeline-item right">
              <div className="mathematics-timeline-content">
                <h3>Trigonometry</h3>
                <p>Inverse Trigonometric Functions</p>
                <p>Further Trigonometric Identities</p>
              </div>
              <div className="mathematics-timeline-icon">
                <p>2</p>
              </div>
            </div>
            <div className="mathematics-timeline-item left">
              <div className="mathematics-timeline-content">
                <h3>Calculus</h3>
                <p>Rates of Change</p>
              </div>
              <div className="mathematics-timeline-icon">
                <p>3</p>
              </div>
            </div>
            <div className="mathematics-timeline-item right">
              <div className="mathematics-timeline-content">
                <h3>Combinatorics</h3>
                <p>Permutations</p>
                <p>Combinations</p>
                <p>Pigeonhole Principle</p>
              </div>
              <div className="mathematics-timeline-icon">
                <p>4</p>
              </div>
            </div>
          </div>
        );
      } else if (activeSubTab === 'year12') {
        return (
          <div className="mathematics-timeline math_extension1-year12">
            <div className="mathematics-timeline-item left">
              <div className="mathematics-timeline-content">
                <h3>Proof and Statistics</h3>
                <p>Mathematical Induction</p>
                <p>The Binomial Distribution</p>
              </div>
              <div className="mathematics-timeline-icon">
                <p>1</p>
              </div>
            </div>
            <div className="mathematics-timeline-item right">
              <div className="mathematics-timeline-content">
                <h3>Vectors</h3>
                <p>Components and Column Vectors</p>
                <p>The Dot Product</p>
                <p>Projections</p>
                <p>Geometric Problems</p>
              </div>
              <div className="mathematics-timeline-icon">
                <p>2</p>
              </div>
            </div>
            <div className="mathematics-timeline-item left">
              <div className="mathematics-timeline-content">
                <h3>Calculus</h3>
                <p>Calculus with Inverse Trigonometry</p>
                <p>Integration by substitution</p>
                <p>Volumes of rotation</p>
                <p>Differential Equations</p>
              </div>
              <div className="mathematics-timeline-icon">
                <p>3</p>
              </div>
            </div>
            <div className="mathematics-timeline-item right">
              <div className="mathematics-timeline-content">
                <h3>Statistics</h3>
                <p>Binomial Probability</p>
                <p>Normal Approximations</p>
                <p>Sample Proportions</p>
              </div>
              <div className="mathematics-timeline-icon">
                <p>4</p>
              </div>
            </div>
          </div>
        );
      }
    } else if (activeMainTab === 'extension2') {
      if (activeSubTab === 'year12') {
        return (
          <div className="mathematics-timeline extension2-year12">
            <div className="mathematics-timeline-item left">
              <div className="mathematics-timeline-content">
                <h3>Complex Numbers</h3>
                <p>Intro to Complex Numbers</p>
                <p>Modulus-argument Form</p>
                <p>Complex Vectors and Polynomials</p>
                <p>De Moivre and Euler</p>
              </div>
              <div className="mathematics-timeline-icon">
                <p>1</p>
              </div>
            </div>
            <div className="mathematics-timeline-item right">
              <div className="mathematics-timeline-content">
                <h3>Proof</h3>
                <p>Language of Proof</p>
                <p>Worded Proofs</p>
                <p>Algebraic Inequalities</p>
                <p>Induction</p>
              </div>
              <div className="mathematics-timeline-icon">
                <p>2</p>
              </div>
            </div>
            <div className="mathematics-timeline-item left">
              <div className="mathematics-timeline-content">
                <h3>Further Integration</h3>
                <p>Algebraic Manipulation</p>
                <p>Partial Fractions</p>
                <p>Integration by Parts</p>
                <p>Reduction Formulae</p>
              </div>
              <div className="mathematics-timeline-icon">
                <p>3</p>
              </div>
            </div>
            <div className="mathematics-timeline-item right">
              <div className="mathematics-timeline-content">
                <h3>Vectors</h3>
                <p>Three Dimensional Vectors</p>
                <p>Vector Proofs</p>
                <p>Vector Equations</p>
              </div>
              <div className="mathematics-timeline-icon">
                <p>4</p>
              </div>
            </div>
            <div className="mathematics-timeline-item left">
              <div className="mathematics-timeline-content">
                <h3>Mechanics</h3>
                <p>Simple Harmonic Motion</p>
                <p>Resisted Motion</p>
                <p>Projectile</p>
              </div>
              <div className="mathematics-timeline-icon">
                <p>5</p>
              </div>
            </div>
          </div>
        );
      }
    }
  };
  

  return (
    <div className="mathematics-overview-container">
      <div className="mathematics-image-container">
        <img src={mathematicsImage} alt="Subjects" className="mathematics-image" />
        <div className="overlay-mathematics"></div>
        <div className="overlay-text-mathematics">
          <h1>Mathematics</h1>
        </div>
      </div>
      <div className="mathematics-book">
        <div className="mathematics-book-content">
          <h2>Book Mathematics Tutoring</h2>
          <p className='mathematics-p'>
          At Prime, our experienced tutors make Mathematics both accessible and enjoyable by matching students with Sydney-based tutors who foster a supportive 
          and encouraging learning atmosphere. 
          </p>
          <p className='mathematics-p'>We offer tutoring for all year levels, from Year 7-10 Mathematics to the HSC including Mathematics Standard, 
          Mathematics Advanced, Mathematics Extension 1, and Mathematics Extension 2.
          </p>
          <p className='mathematics-p'>Additionally, our resource folders come packed with past exam papers, model answers, 
          and comprehensive study guides, ensuring students have all the tools they need to succeed.
          </p>
           
          <a href="/book">
    <button>Book Tutoring</button>
    </a>
        </div>
      </div>
      <div className='mathematics-statement'>
        <h1 className='mathematics-final'>“I find solving math problems enjoyable and love exploring the reasoning behind mathematical concepts. 
          I aim to inspire students to think critically and understand the 'why' behind each step, making math both fun and meaningful.”</h1>
        <p className='mathematics-name'>Issac Liu | Mathematics Tutor</p>
      </div>
      <div className='mathematics-bg'>
        <h1 className="mathematics-title">Course Overview</h1>
        <p className="mathematics-subtitle">Select tab to pick year</p>
        <div className="mathematics-main-tabs">
          <button className={`mathematics-main-tab-button ${activeMainTab === 'math_standard' ? 'active' : ''}`} onClick={() => handleMainTabClick('math_standard')}>Mathematics Standard</button>
          <button className={`mathematics-main-tab-button ${activeMainTab === 'math_advanced' ? 'active' : ''}`} onClick={() => handleMainTabClick('math_advanced')}>Mathematics Advanced</button>
          <button className={`mathematics-main-tab-button ${activeMainTab === 'math_extension1' ? 'active' : ''}`} onClick={() => handleMainTabClick('math_extension1')}>Mathematics Extension 1</button>
          <button className={`mathematics-main-tab-button ${activeMainTab === 'extension2' ? 'active' : ''}`} onClick={() => handleMainTabClick('extension2')}>Mathematics Extension 2</button>
        </div>
        <div className="mathematics-sub-tabs">
  {activeMainTab !== 'extension2' && (
    <button className={`mathematics-tab-button ${activeSubTab === 'year11' ? 'active' : ''}`} onClick={() => handleSubTabClick('year11')}>Year 11</button>
  )}
  <button className={`mathematics-tab-button ${activeSubTab === 'year12' ? 'active' : ''} ${activeMainTab === 'extension2' ? 'extension2-year12-button' : ''}`} onClick={() => handleSubTabClick('year12')}>Year 12</button>
</div>


        <div className={`mathematics-tab-content ${activeSubTab === 'year11' || activeSubTab === 'year12' ? 'active' : ''}`}>
          {renderContent()}
        </div>
      </div>
    </div>
  );
};

export default Mathematics;
